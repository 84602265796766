<template>
  <b-form @submit.prevent>
    <b-card>
      <b-card-body>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <h5 :key="key + 'title'" class="mt-2">
              File Upload - {{ datalist[sel.main].children[sel.sub].title }}
            </h5>
            <b-row>
              <b-col sm="12" md="6" lg="6">
                <validation-provider
                  #default="validationContext"
                  name="Upload File"
                  rules=""
                >
                  <b-form-group label="Upload File " label-for="fileinput">
                    <b-form-file
                      accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf"
                      id="fileinput"
                      name="Upload File "
                      placeholder="Upload File "
                      drop-placeholder="Drop file here..."
                      @change="updateValue($event)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <feather-icon icon="SaveIcon" class="mr-25" /> Add
            </b-button>
          </b-form>

          <div class="mt-3">
            <h4>Import - Export</h4>

            <div class="mt-1" v-for="(item, index) in datalist">
              <h5 style="font-weight: bold">{{ item.title }}</h5>

              <b-row>
                <b-col
                  sm="4"
                  class="mt-1"
                  v-for="(itemx, indexx) in item.children"
                >
                  <h6>{{ itemx.title }}</h6>
                  <b-row class="mt-1">
                    <b-col sm="12" md="6">
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        block
                        @click="selectpage(index, indexx)"
                      >
                        Select
                      </b-button>
                    </b-col>
                    <b-col sm="12" md="4">
                      <a
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        class="btn btn-secondary"
                        block
                        :href="serverurl + itemx.download"
                      >
                        <feather-icon icon="DownloadIcon" class="mr-25" />
                      </a>
                    </b-col>
                    <!-- <span>
                          <div class="col-12 col-md-6 col-sm-12">
                            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" class="px-1" variant="outline-secondary"

                            @click="downloadreportx(index, indexx)">
                              <feather-icon icon="ChevronDownIcon" class="mr-25" />
                              EXP
                            </b-button>
                          </div>

                      </span> -->
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </div>
        </validation-observer>
      </b-card-body>
    </b-card>
  </b-form>
</template>

<script>
import {
  BRow,
  BFormFile,
  BCol,
  BCard,
  BCardBody,
  BFormDatepicker,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormSelect,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import axios from "@axios";

import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import StoreModule from "./StoreModule";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
export default {
  components: {
    BFormSelect,
    BCol,
    BCard,
    BRow,
    BForm,
    flatPickr,
    BCardBody,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormInvalidFeedback,
    BButton,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    BFormDatepicker,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      optioncleaeve: {
        creditCard: true,
      },
    };
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "import-export";
    // Register module
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    const toast = useToast();
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });

    const blankAppData = {
      fileData: {
        name: "",
        file: "",
        size: "",
        type: "",
      },
    };
    const datalist = [
    {
        title: "Settings",
        children: [
          {
            icon: "",
            title: "Country",
            import: "/import/country",
            export: "/export/country",
            download: "/storage/templates/country.xlsx",
          },
          {
            icon: "",
            title: "State",
            import: "/import/state",
            export: "/export/state",
            download: "/storage/templates/state.xlsx",
          },
          // {
          //   icon: "",
          //   title: "Pricing",
          //   import: "/import/pricing",
          //   export: "/export/pricing",
          //   download: "/storage/templates/pricing.xlsx",
          // },
          {
            icon: "",
            title: "Relation",
            import: "/import/relations",
            export: "/export/relations",
            download: "/storage/templates/relations.xlsx",
          },
        ],
      },



      {
        title: "Personal",
        children: [
          {
            icon: "",
            title: "Automobile",
            import: "/import/automobile",
            export: "/export/automobile",
            download: "/storage/templates/automobile.xlsx",
          },
          {
            icon: "",
            title: "Subscriptions",
            import: "/import/subscriptions",
            export: "/export/subscriptions",
            download: "/storage/templates/subscriptions.xlsx",
          },
          {
            icon: "",
            title: "Mobile",
            import: "/import/mobile",
            export: "/export/mobile",
            download: "/storage/templates/mobile.xlsx",
          },
          {
            icon: "",
            title: "Household Equipments",
            import: "/import/householdequipments",
            export: "/export/householdequipments",
            download: "/storage/templates/householdequipments.xlsx",
          },
          {
            icon: "",
            title: "Household Services",
            import: "/import/householdservices",
            export: "/export/householdservices",
            download: "/storage/templates/householdservices.xlsx",
          },
          {
            icon: "",
            title: "Vaccination",
            import: "/import/vaccination",
            export: "/export/vaccination",
            download: "/storage/templates/vaccination.xlsx",
          },
          {
            icon: "",
            title: "Vaccination Dosage",
            import: "/import/vaccinationdosage",
            export: "/export/vaccinationdosage",
            download: "/storage/templates/vaccinationdosage.xlsx",
          },
          // {
          //   icon: "",
          //   title: "Make",
          //   import: "/import/make",
          //   export: "/export/make",
          //   download: "/storage/templates/make.xlsx",
          // },
          {
            icon: "",
            title: "Arts And Collectible",
            import: "/import/artsandcollectible",
            export: "/export/artsandcollectible",
            download: "/storage/templates/artsandcollectible.xlsx",
          },
        ],
      },

      {
        title: "Finance",
        children: [
          // {
          //   icon: "",
          //   title: "Mutual Fund",
          //   import: "/import/mutualfund",
          //   export: "/export/mutualfund",
          //   download: "/storage/templates/mutualfund.xlsx",
          // },

          // {
          //   icon: "",
          //   title: "Bank",
          //   import: "/import/bank",
          //   export: "/export/bank",
          //   download: "/storage/templates/bank.xlsx",
          // },
          // {
          //   icon: "",
          //   title: "Loan",
          //   import: "/import/loan",
          //   export: "/export/loan",
          //   download: "/storage/templates/loan.xlsx",
          // },
          {
            icon: "",
            title: "Wallet",
            import: "/import/wallet",
            export: "/export/wallet",
            download: "/storage/templates/wallet.xlsx",
          },
          {
            icon: "",
            title: "Paylater Wallets",
            import: "/import/paylaterwallets",
            export: "/export/paylaterwallets",
            download: "/storage/templates/paylaterwallets.xlsx",
          },
        ],
      },

      {
        title: "utilities",
        children: [
          {
            icon: "",
            title: "Electricity",
            import: "/import/electricity",
            export: "/export/electricity",
            download: "/storage/templates/electricity.xlsx",
          },
          {
            icon: "",
            title: "Water",
            import: "/import/water",
            export: "/export/water",
            download: "/storage/templates/water.xlsx",
          },
          {
            icon: "",
            title: "Piped Gas",
            import: "/import/gas",
            export: "/export/gas",
            download: "/storage/templates/gas.xlsx",
          },
          {
            icon: "",
            title: "Landbroad",
            import: "/import/landbroad",
            export: "/export/landbroad",
            download: "/storage/templates/landbroad.xlsx",
          },

          {
            icon: "",
            title: "Cable Tv",
            import: "/import/cabletv",
            export: "/export/cabletv",
            download: "/storage/templates/cabletv.xlsx",
          },
        ],
      },
      {
        title: "Insurance",
        children: [
          {
            icon: "",
            title: "Life",
            import: "/import/life",
            export: "/export/life",
            download: "/storage/templates/life.xlsx",
          },
          {
            icon: "",
            title: "General",
            import: "/import/general",
            export: "/export/general",
            download: "/storage/templates/general.xlsx",
          },
        ],
      },
      {
        title: "Transaction",
        children: [
          // {
          //   icon: "",
          //   title: "Income",
          //   import: "/import/income",
          //   export: "/export/income",
          //   download: "/storage/templates/income.xlsx",
          // },
          {
            icon: "",
            title: "Expense",
            import: "/import/expense",
            export: "/export/expense",
            download: "/storage/templates/expense.xlsx",
          },
          {
            icon: "",
            title: "Expense Sub",
            import: "/import/expensesub",
            export: "/export/expensesub",
            download: "/storage/templates/expensesub.xlsx",
          },
        ],
      },

    ];
    const sel = {
      main: 0,
      sub: 0,
    };
    const appData = ref(JSON.parse(JSON.stringify(blankAppData)));
    const onSubmit = () => {
      var temp = appData.value;
      let file = temp.fileData.file;
      let formData = new FormData();
      formData.append("file", file);
      formData.append("status", 1);
      const xdata = {
        url: datalist[sel.main].children[sel.sub].import,
        data: formData,
      };
      store
        .dispatch("import-export/importdata", xdata)
        .then(() => {
          // emit("refetch-data");
          toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              text: modulename + " added successfully",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          router
            .push({
              name: "automobile",
            })
            .catch(() => {});
        })
        .catch((error) => {
          if (error.response.status === 404) {
            appData.value = undefined;
          } else if (error.response.status === 500) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Input fields missing values",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 422) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Please refresh your page",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    };
    const downloadreport = (ctx, callback) => {
      axios({
        url:
          "https://adminfrp.smartfrp.com" +
          datalist[sel.main].children[sel.sub].export,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          let blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = datalist[sel.main].children[sel.sub].title + ".xlsx";
          link.click();
        })
        .catch((res) => {
          console.log(res);
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Couldnt find " + modulenamesub + " details",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .then(() => {
          download.value = 0;
        });
    };
    // const serverurl = "https://adminfrp.smartfrp.com";

    const serverurl = "https://adminfrp.smartfrp.com";
    var key = Math.random();
    return {
      appData,
      onSubmit,
      datalist,
      sel,
      serverurl,
      key,
      downloadreport,
    };
  },
  methods: {
    selectpage(i, j) {
      this.$set(this.sel, "main", i);
      this.$set(this.sel, "sub", j);
      this.key = Math.random();
    },
    downloadreportx(i, j) {
      this.$set(this.sel, "main", i);
      this.$set(this.sel, "sub", j);
      this.downloadreport();
    },
    updateValue(value) {
      if (value.target.files[0].size <= 2 * 1048576) {
        this.appData.fileData.file = value.target.files[0];
        this.appData.fileData.name = value.target.files[0].name;
        this.appData.fileData.size = value.target.files[0].size;
        this.appData.fileData.type = value.target.files[0].type;
      } else {
        this.$swal({
          icon: "error",
          title: "File limit Reached!",
          text: "File size must be maximum 2 mb .",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
